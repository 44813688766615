<template>
  <div>
    <div class="page-header clear-filter" filter-color="orange" style="height: 400px !important;">
      <parallax
        class="page-header-image"
        style="background-image:url('img/cabeceras/index-min.jpg'); filter: blur(1px); -webkit-filter: blur(1px);"
      >
      </parallax>
      <div class="container">
        <div class="content-center brand" style="top: 46% !important">
          <img class="n-logo ml-3" src="img/torreondo.png" />
          <h1 class="h1-seo">Restaurante &nbsp;Torreondo</h1>
          <h4 class="mt-5">En nuestro restaurante te ofrecemos un lugar acogedor para compartir una experiencia divertida y profesional, con un ambiente tranquilo y agradable en su terraza durante todo el año.</h4>
        </div>
      </div>
    </div>

    <!-- LA BARRA -->
    <div class="section" style="padding: 5px 0px !important;">
      <div class="container text-center">
        <div class="row justify-content-md-center">
          <div class="col-md-12 col-lg-8">
            <h2 class="title">La Barra</h2>
            <h5 class="text-primary">
              Para disfrutar del aperitivo, tomar un pintxo o probar nuestras variadas gildas.<br>
              Podrás degustar en mesas altas pintxos fríos y calientes, con productos de máxima 
              calidad y de temporada.
            </h5>
          </div>
        </div>
      </div>
    </div>

    <!-- PICOTEO -->
    <div class="section" style="padding: 5px 0px !important;">
      <div class="container text-center">
        <div class="row justify-content-md-center">
          <div class="col-md-12 col-lg-8">
            <h2 class="title">Picoteo</h2>
            <h5 class="text-primary">
              Sábados y domingos al mediodía, disfruta de nuestras rabas, txopitos
              o rabas de bakalao. También podrás degustar de raciones frías y calientes.
            </h5>
          </div>
        </div>
      </div>
    </div>

    <!-- MENU DEL DIA -->
    <div class="section" style="padding: 5px 0px !important;">
      <div class="container text-center">
        <div class="row justify-content-md-center">
          <div class="col-md-12 col-lg-8">
            <h2 class="title">Menú del día</h2>
            <h5 class="text-primary">
              Disfruta de nuestro menú del día de lunes a viernes, de <b>13:00</b> a <b>16:00</b>.
              Siempre con una gran variedad de primeros, segundos y postres.<br>
              El menú del día en Usansolo mas variado y rico que podrás encontrar.
            </h5>
          </div>
        </div>
      </div>
    </div>

    <!-- CENAS FIN DE SEMANA -->
    <div class="section" style="padding: 5px 0px !important;">
      <div class="container text-center">
        <div class="row justify-content-md-center">
          <div class="col-md-12 col-lg-8">
            <h2 class="title">Cenas</h2>
            <h3 class="text-primary" style="margin-top:-35px;">Fin de semana</h3>
            <h5 class="text-primary">
              Viernes y sábados de <b>20:00</b> a <b>23:00</b> disfruta de nuestras hamburguesas.
              <br><br>Con nuestra novedosa carta, podrás montarte la hamburguesa a tu gusto;<br> Desde elegir
              el tipo de pan y carne, hasta añadirle tantos ingredientes como desees.<br>
              Cena en Usansolo de una forma deliciosa.
            </h5>
          </div>
        </div>
      </div>
    </div>

    <galeria-section></galeria-section>
    <footer-section></footer-section>
  </div>
</template>

<script>
import { Parallax } from '@/components';
import GaleriaSection from './components/GaleriaSection';
import FooterSection from './components/FooterSection';

export default {
  name: 'index',
  bodyClass: 'index-page',
  metaInfo: {
    title: 'Restaurante Torreondo',
    titleTemplate: '%s | El restaurante',
    meta: [
      { name: 'description', content: 'Disfruta de nuestro menú del día de lunes a viernes, de 13:00 a 16:00' },
      { property: 'og:title', content: 'Restaurante Torreondo'},
      { property: 'og:site_name', content: 'Disfruta de nuestro menú del día de lunes a viernes, de 13:00 a 16:00'},
      { property: 'og:description', content: 'Disfruta de nuestro menú del día de lunes a viernes, de 13:00 a 16:00' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://www.restaurantetorreondo.com' },
      { property: 'og:image', content: 'https://www.restaurantetorreondo.com/img/favicon.ico' }
    ]
  },
  mounted() {
    this.$gtag.pageview(this.$route);
  },
  components: {
    Parallax,
    GaleriaSection,
    FooterSection
  }
};
</script>
<style></style>
